import { useState, useEffect } from 'react';

import './Main.css';
import './scheme.css';
import cpu from './assets/images/cpu.png';
import market from './assets/images/market.png';
import gamble1 from './assets/images/gamble1.png';
import trade from './assets/images/trade.png';
import guns from './assets/images/guns.png';
import jackpot from './assets/images/jackpot.png';
import leveling from './assets/images/leveling.jpg';

import Typewriter from 'typewriter-effect';
import CountUp from 'react-countup';
import ImageGallery from "react-image-gallery";

const images = [
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/957476389753499877/2182C1921CE1A2206E120B4B6C14128537E62B4E/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622597805/073ABC4A21526DCE1F498722EDE145D8DFF9867A/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622597818/7C04D9B02D52BC8A47F36FF20028786442504639/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622597828/20EA4AF727F92C0B61D2338FEED3BBB381B0DC53/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622597864/841D6BF0DDA2DB3220B3B827E19FA6772EEDA8F5/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622597888/BE1FBDDD2AF8F9F877C43AB0A40FDD5279C8AED7/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622597903/AE7135AF5885F1A8F4DD7DCC324C9EDA6C72EFA3/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622597955/C950C348AB7410103ED364B8BF1B43E5C3C0DE57/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622597973/F77542353D5E13C29652E084D1AF8D880B47D5C5/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622597985/4C8AE2E5296FFEB573349FD39499627FAF8EFB8D/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622598003/A03A75E9AED2E5172353652CC06AB0A2E05AE328/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622598014/5E11554D70AAC240B9C76ED86293414C1CFB902E/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622598026/5A537683AF6BBC6969A68DE20080CDFFD51F8D67/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622598042/C2AABE2CCAC7BB9205F30C7BD254797B0F9C56B0/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622598065/982660E9EEAF407ECFCDC5F437D8F053B464F927/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/964224906622598090/C73BD2B5D6B11A1ABC5B3F7D1F2D68B33A8E7C9E/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  },
  {
    original: "https://steamuserimages-a.akamaihd.net/ugc/957476389753500604/31BB4F84961662757FE5DE27AFEF869805DFE11A/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false",
    originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"
  }
]

const p2pImages = [
  {original: guns, originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"},
  {original: trade, originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"},
  {original: market, originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"},
  {original: gamble1, originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"},
  {original: jackpot, originalAlt: "A photo of the TitsRP Garry's Mod server running DarkRP"},
]

function Main() {

  const [playtime, setPlaytime] = useState(0);
  const [players, setPlayers] = useState(0);
  const [fps, setFps] = useState(0);
  const [playercount, setPlayercount] = useState(0);

  useEffect(() => {
    fetch('https://titsrp.com/api/server/analytics/playtime')
      .then(blob => blob.json())
      .then(data => {
        setPlaytime(data.total.total_playtime);
      })
      .catch(e => {
        console.log(e);
        return e;
      }
      )

    fetch('https://titsrp.com/api/server/getplayercount')
      .then(blob => blob.json())
      .then(data => {
        setPlayers(data.total_players);
      })
      .catch(e => {
        console.log(e);
        return e;
      }
      )

    fetch('https://titsrp.com/api/server/analytics/fps')
      .then(blob => blob.json())
      .then(data => {
        setFps(data[0].average)
      })
      .catch(e => {
        console.log(e);
        return e;
      }
      )

    fetch('https://api.battlemetrics.com/servers/22160069')
      .then(blob => blob.json())
      .then(data => {
        setPlayercount(data.data.attributes.players)
      })
      .catch(e => {
        console.log(e);
        return e;
      }
      )

  }, []);

  const VideoIDs = [
    "aXlJIJ2JXA0",
    "b0U3pC8ti0E",
    "Fv6VT2HweAw",
    "d8Qo8tvKkVM",
    "oBZ4hxa3nsw",
    "Tt0gkNsttnw",
  ]

  function GetRandomVideoID() {
    return VideoIDs[Math.floor(Math.random() * VideoIDs.length)]
  }

  function ReturnPlayercount() {
    if (playercount > 0) {
      return [playercount, "Online Now"]
    }
    return [fps, "Average FPS"]
  }

  return (
    <div className="App">

      <div className='background'></div>

      <div className='navigation'>
        <div className='navContain'>
          <span id='title'><span style={{ color: '#fc3e3e' }}>T</span>its RP</span>
          <div className='links'>
            <a href='https://forums.titsrp.com'><span>FORUMS</span></a>
            <a href='https://titsrp.com/dashboard'><span>DASHBOARD</span></a>
            <a href='https://discord.gg/ceN6ytn'><span>DISCORD</span></a>
            <a href='https://titsrp.com/store'><span>STORE</span></a>
          </div>
        </div>
      </div>

      <div className='header'>


        <div className='stats'>
          <div id='players'><CountUp end={players} /><span id='statSub'>Total Players</span></div>
          <div id='playtime'><CountUp end={Math.floor(playtime / 3600)} /><span id='statSub'>Hours Played</span></div>
          <div id='playtime'><div><CountUp end={ReturnPlayercount()[0]} /></div><span id='statSub'>{ReturnPlayercount()[1]}</span></div>
        </div>


        <div id='wrap'>

          <div className='join' id='block'>
            <span id='subtitle'>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter.typeString(`"Where your computer doesn't explode..."`)
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString(`"Where memes become dreams!"`)
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString(`"The meme machine, since 2015!"`)
                    .start()
                }}
              />
            </span>
            <span id='desc'>TitsRP is a Garry's Mod roleplay server running a heavily modified version of DarkRP. Experience our map the way it's meant to be played - with more than 90 roleplay jobs, full P2P economy, gangs, battlepass, leveling system, quests, and more!
              <p>We're looking for staff. Join the <a href='https://discord.gg/ceN6ytn'>Discord</a> server and link your Steam account to apply!</p>
            </span>
            <div className='JoinButton' onClick={() => window.location.href = 'steam://connect/193.243.190.39:27015'}><span>CONNECT</span></div>
          </div>

          <div className='headerImages'>
            <iframe
              src={"https://www.youtube.com/embed/" + GetRandomVideoID()}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen>
            </iframe>
          </div>
        </div>

        <div className='map'>
          <ImageGallery items={images} showThumbnails={false} id='gallery' />
          <div className='ourMap' id='block'>
            <span id="blocktitle" >RP Downtown Tits V2</span>
            <span style={{ color: 'grey' }}>143,000+ Subscribers on Steam</span>
            <p>Created for TitsRP by <a href='https://steamcommunity.com/id/Gallium3D'>Gallium</a>, the RP Downtown Tits map has been the go-to map for most DarkRP servers since it's release due to its unique take on the classic Downtown map.</p>
            <p>In 2018, we released the <a href='https://steamcommunity.com/workshop/filedetails/discussion/1527403485/3216031607490179532/'>official Hammer VMF</a>, enabling hundreds of creators and server owners around the world to publish their own <a href='https://steamcommunity.com/workshop/browse/?appid=4000&searchtext=tits&childpublishedfileid=0&browsesort=textsearch&section=readytouseitems&requiredtags%5B%5D=Map&created_date_range_filter_start=0&created_date_range_filter_end=0&updated_date_range_filter_start=0&updated_date_range_filter_end=0'>unique versions</a> to suit their servers.</p>
            <p>Interested in using Downtown Tits on your server? Subscribe to our <a href='https://steamcommunity.com/sharedfiles/filedetails/?id=1527403485'>Workshop</a> for the latest version we use, or create your own!</p>
          </div>
        </div>

        <div className='performance'>
          <div className='imgwrap'>
            <img src={cpu} alt='cpu' /><span style={{ fontSize: '12px', color: 'grey' }}>Image provided by <a href='https://path.net'>Path.net</a>, our upstream provider. <a href='https://blog.path.net/how-to-drop-1-tbps-ddos/'>Source</a></span>
          </div>
          <div id='block'>
            <span id="blocktitle">Fast, Stable, Reliable</span>
            <span style={{ color: 'grey' }}>Highly optimized servers</span>
            <p>TitsRP is built on the idea that gameplay should be as smooth and stable as possible. Our server is hosted on dedicated hardware and delivered to you through a global edge network with 12 Tbps of capacity. This translates to lower latency, better gameplay performance, and no more losing your progress to DDoS attacks.</p>
            <p>If you're tired of joining DarkRP servers where your PC explodes as soon as you leave the spawn tunnel, give us a try. Our addons are made from scratch in-house and are optimized for maximum performance. It's our number one priority.</p>
            <p style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>{`Based on our own analytics data, players can expect an average of ${Math.round(fps)} frames per second while playing.`} <span style={{ fontSize: '12px', color: 'grey', marginTop: '5px' }}>Data includes players who have their FPS capped in settings.</span></p>
          </div>
        </div>


        <div className='economy'>
          <ImageGallery items={p2pImages} showThumbnails={false} style={{ maxHeight: '342px' }} />
          <div id='block'>
            <span id="blocktitle" >Robust P2P Economy</span>
            <span style={{ color: 'grey' }}>Player-controlled economy</span>
            <p>When everything is for sale and tradeable, the economy is controlled by the players. Set up a black market gun store, wholesale materials needed for crafting, flip items on the auction house for a profit, or take a gamble on the slots or roulette table. The possibilities are endless—just make sure you sell at the right time to maximize profits!</p>
            <p>Gambling should be fun and not a way to get rich quickly. If you're not having fun, we give you the option to permanently disable all forms of gambling on the server with a single button press.</p>
          </div>
        </div>

        <div className='performance'>
          <div className='imgwrap'>
            <img src={leveling} alt='leveling' style={{ height: '330px' }} />
          </div>
          <div id='block'>
            <span id="blocktitle">XP & Player Leveling</span>
            <span style={{ color: 'grey' }}>Endless opportunities for progression</span>
            <p>There are 55 character levels in TitsRP that you can unlock by earning XP. Each level unlocks a new perk that provides you with a unique advantage.</p>
            <p>Keep your perks or prestige after reaching level 55. Each time you prestige, you are given one prestige point to spend on unique character upgrades, perks, and pets.</p>
            <p>There are over 150 ways to earn XP in-game. Track your progress each day in the leveling menu.</p>
          </div>
        </div>


        <div>
          <p style={{ textAlign: 'center', color: 'white', marginTop: '10px', fontSize: '30px', textShadow: 'rgb(22 22 22) 2px 0px 4px' }}><a href='steam://connect/193.243.190.39:27015'>Join now</a> to see what else TitsRP has to offer!</p>
        </div>


        <div className='copyright'>© {new Date().getFullYear()} TitsRP. All rights reserved.</div>

      </div>

    </div>

  );
}

export default Main;
